import { useEffect, useState } from "react"
import { useProfileStore } from "@/store/profileStore"
import { usePoliciesStore } from "@/store/subscriptionStore"
import { useSubscriptionStore } from "@/store/subscriptionStore"
import { loadTossPayments } from "@tosspayments/payment-sdk"
import { workspaceId } from "@/app/api/config"
import { ActivePaymentMethodResponse } from "@/app/api/billing/billing-api-models"
import { useLoadingOverlayStore } from "@/store/loadingOverlayStore"
import { getActivePaymentMethod, startNewSubscription } from "@/app/api/billing/api"

import Modal from "@/components/Modal/Modal"
import PlanInfoModal from "./PlanInfoModal"
import PlanCard from "./PlanCard"
import Confirmation from "@/components/Confirmation/Confirmation"
import MiniAlert from "@/components/MiniAlert/MiniAlert"
import { useChannelTalk } from "@/hooks/useChannelTalk"
import { BoltaEvent } from "@/utils/mixpanels"

type Props = {
  isOpen: boolean
  isDismissable?: boolean
  subTitle?: string
  children?: React.ReactNode
  variant?: "default" | "danger"
  onOpenChange: (open: boolean) => void
}

export default function PlanModal({
  isOpen,
  isDismissable = true,
  children,
  subTitle,
  variant = "default",
  onOpenChange,
}: Props) {
  const authorizedFeatures = useProfileStore.getState().getAuthorizedFeatures()
  const [activePaymentMethod, setActivePaymentMethod] =
    useState<ActivePaymentMethodResponse | null>()
  const subscriptionInfo = useSubscriptionStore(state => state.subscriptionInfo)
  const currentKey = subscriptionInfo?.subscription?.policyKey
  const [planInfoModalOpen, setPlanInfoModalOpen] = useState<boolean>(false)
  const [selectedPolicyKey, setSelectedPolicyKey] = useState<string | null>()
  const channelTalk = useChannelTalk()
  const policies = usePoliciesStore(state => state.policies)
  const selectedPolicy = policies ? policies[selectedPolicyKey ?? ""] : null
  const startLoadig = useLoadingOverlayStore(state => state.startLoading)
  const isFreeTrial = subscriptionInfo?.subscription?.isFreeTrial
  const isBlockedByPaymentFailed =
    subscriptionInfo?.subscription?.blockStatus === "BLOCKED_BY_PAYMENT_FAILED"

  useEffect(() => {
    ;(async () => {
      if (isOpen) {
        const response = await getActivePaymentMethod()
        setActivePaymentMethod(response)
      }
    })()
  }, [isOpen])

  const handleStartNewSubscription = async (
    policyId: number,
    policyKey: string,
    originalPrice: number,
    discountPrice: number,
  ) => {
    if (!policyId) return

    if (activePaymentMethod === null && selectedPolicyKey !== "POLICY_FREE") {
      onOpenChange(false)

      const tossPaymentsClientKey =
        process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY ?? ""
      const tossPayments = await loadTossPayments(tossPaymentsClientKey)
      const customerKey = btoa(`TOSS_PG_${workspaceId}`)
      const successUrlPayload = btoa(
        JSON.stringify({
          policyId,
          policyKey,
          originalPrice,
          discountPrice,
        }),
      )
      await tossPayments
        .requestBillingAuth("카드", {
          customerKey: customerKey,
          successUrl: `${window.location.origin}/billing/register-card/success?payload=${successUrlPayload}`,
          failUrl: `${window.location.origin}/billing/register-card/failure`,
        })
        .catch(function (error) {
          if (error.code === "USER_CANCEL") {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
            window.location.reload()
          }
        })
      return
    }

    startLoadig()
    startNewSubscription(policyId).then(res => {
      if (!res) return
      window.location.reload()
    })
  }

  const trialExpired =
    (subscriptionInfo?.subscription?.expiresAt || 0) < new Date().getTime()

  if (!policies || !isOpen) return null

  return (
    <>
      {authorizedFeatures.includes("BILLING_MANAGEMENT") ? (
        <Modal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          scrollBehavior="inside"
          isDismissable={isDismissable}
          hideCloseButton={!isDismissable}
          classNames={{
            base: "max-w-[1080px]",
            closeButton: "text-[24px] text-quaternary m-2",
            backdrop: "bg-[rgba(23,29,35,0.50)]",
          }}
          headerContent={
            <div>
              <div className="mt-1 text-2xl font-semibold">플랜 선택</div>
              {subTitle && (
                <div className="text-quaternary mt-1 text-[16px] font-normal">
                  {subTitle}
                </div>
              )}
            </div>
          }
        >
          {children && (
            <MiniAlert isOpen={true} variant={variant} className="mb-6">
              {children}
            </MiniAlert>
          )}
          <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:gap-6">
            <PlanCard
              policyKey="POLICY_FREE"
              policy={policies["POLICY_FREE"]}
              isDisabled={
                (currentKey === "POLICY_FREE" || isFreeTrial) && !trialExpired
              }
              buttonText={currentKey === "POLICY_FREE" ? "현재플랜" : "시작"}
              onClick={() => {
                BoltaEvent.track("clicked__start_subscription__subscription_modal", {
                  plan: "POLICY_FREE",
                })
                setSelectedPolicyKey("POLICY_FREE")
                setPlanInfoModalOpen(true)
              }}
            />
            <PlanCard
              policyKey="POLICY_BASIC"
              policy={policies["POLICY_BASIC"]}
              isDisabled={currentKey === "POLICY_BASIC" && !isBlockedByPaymentFailed}
              buttonText={currentKey === "POLICY_BASIC" ? "현재플랜" : "시작"}
              onClick={() => {
                BoltaEvent.track("clicked__start_subscription__subscription_modal", {
                  plan: "POLICY_BASIC",
                })
                setSelectedPolicyKey("POLICY_BASIC")
                setPlanInfoModalOpen(true)
              }}
            />
            <PlanCard
              policyKey="POLICY_PLUS"
              policy={policies["POLICY_PLUS"]}
              isDisabled={
                currentKey === "POLICY_PLUS" &&
                !isFreeTrial &&
                !isBlockedByPaymentFailed
              }
              buttonText={
                currentKey !== "POLICY_PLUS" || (isFreeTrial && trialExpired)
                  ? "시작"
                  : "현재플랜"
              }
              onClick={() => {
                BoltaEvent.track("clicked__start_subscription__subscription_modal", {
                  plan: "POLICY_PLUS",
                })
                setSelectedPolicyKey("POLICY_PLUS")
                setPlanInfoModalOpen(true)
              }}
            />
            <PlanCard
              policyKey="POLICY_ENTERPRISE"
              policy={{
                id: 0,
                displayName: "엔터프라이즈",
                originalPrice: 0,
                discountPrice: 0,
                displayPrice: 0,
                displayTax: 0,
                status: "",
              }}
              isDisabled={currentKey === "POLICY_ENTERPRISE"}
              buttonText={currentKey === "POLICY_ENTERPRISE" ? "현재플랜" : "상담"}
              onClick={channelTalk.openMessenger}
            />
          </div>
        </Modal>
      ) : (
        <Confirmation
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          title="플랜 업그레이드 필요"
          confirmText="네, 확인했어요"
          variant="alert"
          isDismissable={isDismissable}
        >
          구독 중인 플랜 업그레이드가 필요합니다.
          <br />
          워크스페이스의 관리자에게 문의하세요.
        </Confirmation>
      )}
      {selectedPolicy && (
        <PlanInfoModal
          isOpen={planInfoModalOpen}
          selectedPolicyKey={selectedPolicyKey}
          selectedPolicy={selectedPolicy}
          onOpenChange={setPlanInfoModalOpen}
          onSubmitClick={() => {
            handleStartNewSubscription(
              selectedPolicy.id,
              selectedPolicyKey ?? "",
              selectedPolicy.originalPrice,
              selectedPolicy.discountPrice,
            )
          }}
        />
      )}
    </>
  )
}
