import { Modal, ModalContent, ModalBody, ModalFooter } from "@nextui-org/react"
import IconAlert from "@/assets/icons/confirm-icon-1.svg"
import IconConfirm from "@/assets/icons/confirm-icon-2.svg"
import { ModalProps, Divider } from "@nextui-org/react"

interface Props extends ModalProps {
  variant?: "confirm" | "alert"
  title?: string
  confirmText?: string
  hideButtons?: boolean
  isDismissible?: boolean
  onOpenChange?: (isOpen: boolean) => void
  onConfirm?: () => void
}

export default function Confirmation({
  children,
  isOpen,
  variant = "confirm",
  title,
  confirmText = "확인",
  isDismissable = true,
  hideButtons,
  onOpenChange,
  onConfirm,
  className,
  ...props
}: Props) {
  if (!isOpen) return null

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="sm"
      hideCloseButton
      isKeyboardDismissDisabled={!isDismissable}
      isDismissable={isDismissable}
      backdrop="blur"
      classNames={{
        base: className,
        closeButton: "text-[24px] text-quaternary m-2",
        wrapper: "z-[1301]",
        backdrop: "bg-[rgba(23,29,35,0.50)] z-[1300]",
      }}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0,
            },
          },
          exit: {
            y: 0,
            opacity: 0,
            transition: {
              duration: 0,
            },
          },
        },
      }}
      {...props}
    >
      <ModalContent>
        <ModalBody>
          <div className="mb-2 mt-5 flex justify-center">
            {variant === "confirm" ? <IconAlert /> : <IconConfirm />}
          </div>
          <div className="text-center">
            {title && (
              <div className="text-base-color mb-2 text-lg font-bold">{title}</div>
            )}
            <div className="text-quaternary pb-6 text-sm leading-6">{children}</div>
          </div>
        </ModalBody>
        {!hideButtons && (
          <ModalFooter className="flex border-t py-4">
            <div className="flex w-full justify-center">
              <button
                className="text-quaternary w-full py-2 text-sm font-semibold"
                onClick={() => {
                  if (onOpenChange) onOpenChange(false)
                }}
              >
                취소
              </button>

              <Divider orientation="vertical" className="bg-[#EEEFF1]" />

              <button
                className={`${
                  variant === "confirm" ? "text-primary" : "text-secondary"
                } w-full py-2 text-sm font-semibold`}
                onClick={() => {
                  if (onConfirm) onConfirm()
                  if (onOpenChange) onOpenChange(false)
                }}
              >
                {confirmText}
              </button>
            </div>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}
