import { Policy } from "@/store/subscriptionStore"
import { formatNumber } from "@/utils/formats"
import Modal from "@/components/Modal/Modal"
import { Button } from "@nextui-org/react"
import { BoltaEvent } from "@/utils/mixpanels"

type Props = {
  isOpen: boolean
  selectedPolicy: Policy
  selectedPolicyKey: string | null | undefined
  onOpenChange: (open: boolean) => void
  onSubmitClick: () => void
}

export default function PlanInfoModal({
  isOpen,
  selectedPolicy,
  selectedPolicyKey,
  onOpenChange,
  onSubmitClick,
}: Props) {
  if (!selectedPolicy || !selectedPolicyKey) return null

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior="inside"
      size="xl"
      backdrop="blur"
      headerContent={
        <div>
          <div className="mt-1 text-2xl font-semibold">플랜 결제 정보</div>
          <div className="text-quaternary mt-1 text-[16px] font-normal">
            플랜 결제 정보를 확인해보세요.
          </div>
        </div>
      }
      footerContent={
        <div>
          <Button
            size="lg"
            color="primary"
            className="h-[44px] w-full"
            onClick={() => {
              BoltaEvent.track(
                "clicked__subscription_payment__subscription_payment_confirmation",
                {
                  plan: selectedPolicyKey,
                  originalPrice: selectedPolicy.originalPrice,
                  discountPrice: selectedPolicy.discountPrice,
                },
              )
              onSubmitClick()
            }}
          >
            결제
          </Button>
        </div>
      }
    >
      <div className="bg-sub rounded-lg px-6 pb-2 pt-5">
        <h2>{selectedPolicy.displayName}</h2>
        <ul className="small-list">
          <li>
            <div className="title">정상 금액</div>
            <div className="content">
              {formatNumber(selectedPolicy.originalPrice)}원
            </div>
          </li>
          <li>
            <div className="title flex items-center">
              <span>할인 금액</span>
            </div>
            <div className="content flex items-center">
              {selectedPolicyKey !== "POLICY_FREE" && (
                <div className="mr-2 rounded border border-[#EF2A2B] px-[6px] text-[12px] font-medium text-danger">
                  {selectedPolicy.status === "PARTNER" ? "제휴할인" : "출시기념"}
                </div>
              )}
              <span
                className={`${
                  selectedPolicyKey !== "POLICY_FREE" ? "text-danger" : ""
                }`}
              >
                - {formatNumber(selectedPolicy.discountPrice)}원
              </span>
            </div>
          </li>
          <hr className="my-2" />
          <li>
            <div className="title">총 결제금액</div>
            <div className="content text-[16px]">
              {formatNumber(selectedPolicy.displayPrice + selectedPolicy.displayTax)}
              원
              {selectedPolicyKey !== "POLICY_FREE" && (
                <div className="text-quaternary mt-1 text-[13px]">*VAT(10%) 포함</div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </Modal>
  )
}
