import Icon1 from "@/assets/icons/mini-icon-1.svg"
import Icon2 from "@/assets/icons/mini-icon-2.svg"
import Icon3 from "@/assets/icons/mini-icon-3.svg"

type Props = {
  isOpen: boolean
  variant?: "success" | "danger" | "default"
  children: React.ReactNode
  className?: string
}

export default function MiniAlert({
  isOpen,
  variant = "success",
  children,
  className,
}: Props) {
  const getIcon = () => {
    switch (variant) {
      case "success":
        return <Icon1 />
      case "danger":
        return <Icon2 />
      default:
        return <Icon3 />
    }
  }

  return (
    <div className={`mini-alert ${isOpen ? "block" : "hidden"} ${className}`}>
      <div
        className={`mini-alert-wrapper ${`mini-alert-${variant}`} md:items-center`}
      >
        <div className="icon">{getIcon()}</div>
        <div className="grow">{children}</div>
      </div>
    </div>
  )
}
