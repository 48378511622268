import { create } from "zustand"
import { SubscriptionInfo } from "@/types/subscription"

export type Policy = {
  id: number
  displayName: string
  originalPrice: number
  discountPrice: number
  displayPrice: number
  displayTax: number
  status: string
}

type Policies = {
  [key: string]: Policy
}

interface SubscriptionState {
  subscriptionInfo: SubscriptionInfo | null
  isSubscriptionLoaded: boolean
  isIssuanceExeedCapcity: (subscriptionInfo: SubscriptionInfo | null) => boolean
  setSubscriptionInfo: (subscriptionInfo: SubscriptionInfo | null) => void
  setSubscriptionLoaded: () => void
}

interface PoliciesState {
  policies: Policies | null
  setPolicies: (policies: Policies | null) => void
  getPolicyKey: (policyId: number | null) => string | null | undefined
  getPolicyId: (policyKey: string | null) => number | null
}

export const useSubscriptionStore = create<SubscriptionState>()(set => ({
  isSubscriptionLoaded: false,
  isIssuanceExeedCapcity: subscriptionInfo => {
    if (!subscriptionInfo || !subscriptionInfo.subscription) return false

    return (
      !!subscriptionInfo.subscription.issuanceCapacity &&
      subscriptionInfo.currentUsage.issuanceCount >=
        subscriptionInfo.subscription.issuanceCapacity
    )
  },
  subscriptionInfo: null,
  setSubscriptionInfo: subscriptionInfo => set({ subscriptionInfo }),
  setSubscriptionLoaded: () => set({ isSubscriptionLoaded: true }),
}))

export const usePoliciesStore = create<PoliciesState>()((set, get) => ({
  policies: null,
  setPolicies: policies => set({ policies }),
  getPolicyKey: policyId => {
    if (!policyId) return null

    for (const [key, value] of Object.entries(get().policies || {})) {
      if (value.id === policyId) {
        return key
      }
    }
  },
  getPolicyId: policyKey => {
    if (!policyKey) return null

    for (const [key, value] of Object.entries(get().policies || {})) {
      if (key === policyKey) {
        return value.id
      }
    }

    return null // 키가 없는 경우 null 반환
  },
}))
