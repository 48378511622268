import { instance } from "@/app/api/api"
import { BaseResponse } from "@/app/api/baseResponse-api-models"
import {
  ActivePaymentMethodResponse,
  CurrentSubscriptionResponse,
  RegisterCardRequest,
  RegisterCardResponse,
  SubscriptionHistoryResponse,
} from "@/app/api/billing/billing-api-models"
import { workspaceId } from "@/app/api/config"
import { usePageSizeStore } from "@/store/layoutStore"
import { PageResponse } from "@/app/api/page-api-models"

export const registerCard = async (
  request: RegisterCardRequest,
): Promise<RegisterCardResponse> => {
  const url = `/api/v1/workspaces/${workspaceId}/payments:registerCard`

  const {
    data: { body },
  } = await instance.post<BaseResponse<RegisterCardResponse>>(url, request)

  return body
}

export const getSelectablePolicies = async () => {
  if (!workspaceId) {
    return []
  }

  const url = `/api/v1/workspaces/${workspaceId}/policies:selectable`

  const {
    data: { body },
  } = await instance.get(url)

  return body
}

export async function getCurrentSubscription(): Promise<CurrentSubscriptionResponse | null> {
  if (!workspaceId) {
    return null
  }

  const url = `/api/v1/workspaces/${workspaceId}/subscriptions`

  const {
    data: { body },
  } = await instance.get<BaseResponse<CurrentSubscriptionResponse | null>>(url)

  return body
}

export async function getSubscriptionHitories(options: {
  page: number
  size?: number
}): Promise<PageResponse<SubscriptionHistoryResponse>> {
  const url = `/api/v1/workspaces/${workspaceId}/subscriptions:history`

  const queryParams = {
    size: options.size || usePageSizeStore.getState().pageSize,
    page: options.page,
  }

  const {
    data: { body },
  } = await instance.get<BaseResponse<PageResponse<SubscriptionHistoryResponse>>>(
    url,
    {
      params: queryParams,
    },
  )

  return body
}

export async function getActivePaymentMethod(): Promise<ActivePaymentMethodResponse | null> {
  const url = `/api/v1/workspaces/${workspaceId}/payments:activePaymentMethod`

  const {
    data: { body },
  } = await instance.get<BaseResponse<ActivePaymentMethodResponse>>(url)

  return body
}

export const startFreeTrial = async (policyId: number): Promise<void> => {
  const url = `/api/v1/workspaces/${workspaceId}/subscriptions:freeTrial`

  await instance.post<BaseResponse<void>>(url, { policyId })
}

export const startNewSubscription = async (
  policyId: number,
): Promise<{ id: number }> => {
  const url = `/api/v1/workspaces/${workspaceId}/subscriptions`

  const res = await instance.post(url, { policyId })

  return res.data.body
}
